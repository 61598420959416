import { useSession } from "next-auth/react";
import dynamic from "next/dynamic";
import {
  useGetUserLastVisitedQuery,
  useGetUserWatchlistQuery,
  useGetVehiclesQuery,
} from "../../graphql/generated/graphql";
import { GridLoadingSkeleton } from "../Grid";
// import CategoryGrid from "../CategoryGrid";
// import WatchlistEmptyState from "../WatchlistEmptyState";
import GoToWatchlistCard from "../GoToWatchlistCard";
import Link from "next/link";
import Map from "../Map";
import FavoriteSearches from "../FavoriteSearches";
// import SellYourCarCTA from "../SellYourCarCTA";
import FollowUsNotification from "../FollowUsNotification";
import Banners from "../Banners";
// import BrandGrid from "../BrandGrid";
import CollectionBanner from "../collection/Banner";
import { TIER_GRID_LENGTH, TIER_KEYS } from "../../utils/constants";
import { HeroSwiper } from "./HeroSwiper";

const DynamicGridCollection = dynamic(() => import("../Grid"), {
  loading: () => (
    <GridLoadingSkeleton
      gridColsClassNames="grid-cols-1 md:grid-cols-4"
      limit={TIER_GRID_LENGTH[TIER_KEYS.COLLECTION]}
    />
  ),
});

const DynamicGridMax = dynamic(() => import("../Grid"), {
  loading: () => (
    <GridLoadingSkeleton limit={TIER_GRID_LENGTH[TIER_KEYS.MAX]} />
  ),
});

const DynamicGridFree = dynamic(() => import("../Grid"), {
  loading: () => (
    <GridLoadingSkeleton limit={TIER_GRID_LENGTH[TIER_KEYS.FREE]} />
  ),
});

const DynamicCategoryGrid = dynamic(() => import("../CategoryGrid"), {
  loading: () => <div>Loading</div>,
});

const DynamicBrandGrid = dynamic(() => import("../BrandGrid"), {
  loading: () => <div>Loading</div>,
});

const DynamicSellYourCarCTA = dynamic(() => import("../SellYourCarCTA"), {
  loading: () => <div>Loading</div>,
});

export default function NewHomepage({ vehiclesCollection }) {
  const { status: sessionStatus } = useSession();

  const {
    data: dataCollectionClientSide,
    loading: loadingLatestCollectionVehiclesClientSide,
    // error,
  } = useGetVehiclesQuery({
    variables: {
      limit: TIER_GRID_LENGTH[TIER_KEYS.COLLECTION],
      filters: {
        listingTier: TIER_KEYS.COLLECTION,
      },
    },
    skip: vehiclesCollection?.length,
    fetchPolicy: "cache-and-network",
  });

  const { getVehicles: getVehiclesCollectionClientSide } =
    dataCollectionClientSide || {};
  const { data: vehiclesCollectionClientSide } =
    getVehiclesCollectionClientSide || {};

  const {
    data,
    loading: loadingLatestMaxVehicles,
    // error,
  } = useGetVehiclesQuery({
    variables: {
      limit: TIER_GRID_LENGTH[TIER_KEYS.MAX],
      filters: {
        listingTier: TIER_KEYS.MAX,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const { getVehicles } = data || {};
  const { data: vehiclesMax } = getVehicles || {};

  // const {
  //   data: dataElectric,
  //   loading: loadingLatestElectricVehicles,
  //   // error,
  // } = useGetVehiclesQuery({
  //   variables: {
  //     limit: 9,
  //     filters: {
  //       // listingTier: TIER_KEYS.PLUS,
  //       fuel: ["electric"],
  //     },
  //   },
  //   fetchPolicy: "cache-and-network",
  // });

  // const { getVehicles: getvehiclesElectric } = dataElectric || {};
  // const { data: vehiclesElectric } = getvehiclesElectric || {};

  const {
    data: dataFree,
    loading: loadingLatestFreeVehicles,
    // error,
  } = useGetVehiclesQuery({
    variables: {
      limit: TIER_GRID_LENGTH[TIER_KEYS.FREE],
      filters: {
        listingTier: TIER_KEYS.FREE,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const { getVehicles: getVehiclesFree } = dataFree || {};
  const { data: vehiclesFree } = getVehiclesFree || {};

  const { data: watchlistData, loading: loadingWatchlist } =
    useGetUserWatchlistQuery({
      skip: sessionStatus !== "authenticated",
    });

  const { data: lastVisitedData, loading: loadingLastVisited } =
    useGetUserLastVisitedQuery({
      skip: sessionStatus !== "authenticated",
    });

  const unauthenticated = sessionStatus !== "authenticated";

  return (
    <>
      {/* <div className="sm:mt-6">
        <Banners />
      </div> */}

      <main className="max-w-7xl mx-auto">
        <div className="mb-6">
          <HeroSwiper
            loading={
              !vehiclesCollection?.length
                ? loadingLatestCollectionVehiclesClientSide
                : false
            }
            collectionVehicles={
              vehiclesCollection?.length
                ? vehiclesCollection
                : vehiclesCollectionClientSide
            }
          />
        </div>
        <DynamicGridCollection
          loading={
            !vehiclesCollection?.length
              ? loadingLatestCollectionVehiclesClientSide
              : false
          }
          items={
            vehiclesCollection?.length
              ? vehiclesCollection
              : vehiclesCollectionClientSide
          }
          title={<CollectionBanner />}
          // colsClassNames="grid-cols-1 md:grid-cols-4"
          transformation="t_medium"
          limit={TIER_GRID_LENGTH[TIER_KEYS.COLLECTION]}
        />
        <DynamicGridMax
          limit={TIER_GRID_LENGTH[TIER_KEYS.MAX]}
          loading={loadingLatestMaxVehicles}
          items={vehiclesMax}
          title={
            <span className="">
              Últimos autos <span className="text-blue-500">MAX</span>
            </span>
          }
          description="Autos destacados publicados por vendedores directos"
          LastElement={() => (
            <GoToWatchlistCard
              pathname="/autosusados"
              icon="list"
              label="Ver todos"
            />
          )}
        />
        {/* 
        <Grid
          loading={loadingLatestElectricVehicles}
          items={vehiclesElectric}
          title={
            <span className="">
              Últimos autos <span className="text-emerald-500">Eléctricos</span>
            </span>
          }
          description="Autos eléctricos publicados por vendedores directos"
          LastElement={() => (
            <GoToWatchlistCard
              pathname="/electricos"
              icon="list"
              label="Ver todos"
            />
          )}
        /> */}

        <DynamicGridFree
          limit={TIER_GRID_LENGTH[TIER_KEYS.FREE]}
          loading={loadingLatestFreeVehicles}
          items={vehiclesFree}
          title={<span className="">Otros autos que te podrían interesar</span>}
          description="Autos publicados de forma gratuita por vendedores directos"
          LastElement={() => (
            <GoToWatchlistCard
              pathname="/autosusados"
              icon="list"
              label="Ver todos"
            />
          )}
        />

        <div className="my-10">
          <Banners />
        </div>
        <div className="px-4 mb-6">
          <FollowUsNotification fixed />
        </div>
        <DynamicCategoryGrid />
        <DynamicBrandGrid />
        <Map />
        {/* {!unauthenticated && lastVisitedData?.getUserLastVisited?.length ? (
          <Grid
            loading={loadingLastVisited}
            items={lastVisitedData?.getUserLastVisited || []}
            title="Vistos recientemente"
            limit={5}
          />
        ) : null}
        <Grid
          loading={loadingWatchlist || sessionStatus === "loading"}
          items={watchlistData?.getUserWatchlist || []}
          title="Tus Autos Favoritos"
          limit={7}
          EmptyStateComponent={WatchlistEmptyState}
          LastElement={() => (
            <GoToWatchlistCard
              pathname="/favorites"
              icon="favorites"
              label="Ir a favoritos"
            />
          )}
        /> */}
        <FavoriteSearches limit={5} />
        <DynamicSellYourCarCTA />
        <div className="w-full mt-12 flex items-center justify-center">
          <Link href="/autosusados">
            <span className="cursor-pointer flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 md:py-4 md:text-lg md:px-10">
              Ver todos los autos usados
            </span>
          </Link>
        </div>
      </main>
    </>
  );
}
