import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import HeroGalery from "./HeroGalery";

import { register } from "swiper/element/bundle";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import type { SwiperSlideProps, SwiperProps } from "swiper/react";
import { Vehicle } from "../../graphql/generated/graphql";
import { classNames, formatPrice } from "../../utils/formatters";
const swiperStyles = [
  `
    .swiper-button-next,
    .swiper-button-prev {
      font-size: 40px;
      display: absolute;
      top: 80%;
      left: 85%;
      background-image: url("/arrow.png");
      background-color: white;
      background-position: center;
      background-size: 60px 60px;
      background-repeat: no-repeat;
      padding: 8px 16px;
      border-radius: 100%;
      border: 2px solid black;
      color: red;
    }

    .swiper-button-prev {
      transform:rotate(-180deg)
    }

    .swiper-button-next {
      transform: translate(100%)
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
      content: "";
    } 
`,
];

type CollectionVehicleType = Array<Vehicle>;
const AUTOPLAY_DELAY = "4500"; //in ms

//web components shitty typescript definition
//there's no typescript support yet (https://github.com/nolimits4web/swiper/issues/6466)
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "swiper-container": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & SwiperProps,
        HTMLElement
      >;
      "swiper-slide": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & SwiperSlideProps,
        HTMLElement
      >;
    }
  }
}

export const HeroSwiper = ({
  loading,
  collectionVehicles = [],
}: {
  loading?: boolean;
  collectionVehicles: CollectionVehicleType;
}) => {
  const swiperElRef = useRef(null);
  const [swiperIsMounted, setSwiperIsMounted] = useState(false);

  const handleNext = useCallback(() => {
    if (!swiperElRef.current) return;
    swiperElRef.current.swiper.slideNext();
  }, []);

  const handlePrev = useCallback(() => {
    if (!swiperElRef.current) return;
    swiperElRef.current.swiper.slidePrev();
  }, []);

  const itemsArray = useMemo(() => {
    if (!loading && collectionVehicles?.length) {
      return collectionVehicles
        .map(
          ({
            _id,
            slug,
            thumbs,
            pictures,
            price,
            currency,
            brand,
            model,
            year,
          }) => ({
            _id,
            title: `${brand} ${model} ${year}`,
            slug,
            thumbs,
            pictures,
            price: formatPrice(price, currency),
            sort: Math.random(),
          })
        )
        .sort((a, b) => a.sort - b.sort)
        .map(({ sort, ...vehicleData }) => vehicleData);
    }

    return [];
  }, [loading, collectionVehicles]);

  useEffect(() => {
    if (!swiperIsMounted && !loading) {
      setSwiperIsMounted(true);
    } else if (swiperIsMounted) {
      register();
      swiperElRef.current.initialize();
    }
  }, [swiperIsMounted, loading]);

  if (!swiperIsMounted) {
    return <HeroGalleryLoadingSkeleton />;
  }

  return (
    <div className="relative">
      <swiper-container
        ref={swiperElRef}
        effect="fade"
        slides-per-view="1"
        init={false}
        space-between="30"
        autoplay={true}
        autoplay-delay={AUTOPLAY_DELAY}
        autoplay-disable-on-interaction="true"
        rewind={true}
        injectStyles={swiperStyles}
        virtual={true}
        cssMode={true}
      >
        {itemsArray.map((item, idx) => {
          const vehicleItem = item;
          const vehicleThumbs = vehicleItem.thumbs;

          return (
            <swiper-slide
              key={`slide-${item._id}`}
              lazy={true}
              virtualIndex={idx}
            >
              <HeroGalery
                title={vehicleItem.title}
                price={vehicleItem.price}
                picture={vehicleItem.pictures[0]}
                thumbs={vehicleThumbs}
                slug={item.slug}
                index={idx}
              />
            </swiper-slide>
          );
        })}
      </swiper-container>
      <div className="absolute transition-all duration-700 z-10 bottom-2 right-2 lg:right-4 ">
        <button
          onClick={handlePrev}
          className="bg-white text-gray-400 hover:text-gray-600 p-1 transition-all rounded-l-md backdrop-blur-sm opacity-85 md:opacity-80"
        >
          <ChevronLeftIcon className="h-6 w-6 " aria-hidden="true" />
        </button>

        <button
          onClick={handleNext}
          className="bg-white  text-gray-400 hover:text-gray-600 p-1 transition-all rounded-r-md backdrop-blur-sm  opacity-85 md:opacity-80"
        >
          <ChevronRightIcon className="h-6 w-6 " aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export function HeroGalleryLoadingSkeleton() {
  return (
    <div className="  animate-pulse w-full min-h-[400px] gap-1 grid grid-cols-1 gap-y-1 sm:grid-cols-3 sm:gap-y-0 sm:gap-x-1 grid-rows-3 sm:grid-rows-1 lg:px-2 ">
      <div className="relative col-span-2 row-span-2 bg-gray-200"></div>

      {/* second div  */}
      <div className="relative md:min-h-[400px] grid gap-1 h-full grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 ">
        {/* small thumbs */}

        {[...Array(4)].map((_, index) => {
          return (
            <div
              className={classNames(
                "relative aspect-w-4 aspect-h-3 w-full h-full bg-gray-200 ",
                index === 2 || index === 3 ? "hidden lg:block" : ""
              )}
              key={index}
            ></div>
          );
        })}
      </div>
    </div>
  );
}
